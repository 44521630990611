import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
  PLATFORM_ID,
  AfterViewInit,
  Renderer2,
} from '@angular/core';
import { NavigationEnd, NavigationStart, Router, RouterOutlet } from '@angular/router';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

import { TranslateService } from '@ngx-translate/core';
import * as AOS from 'aos';
import { filter, tap } from 'rxjs';

import { CountriesService, LanguageService } from '@shared/services';
import { staticContentPageRouting } from '@shared/models';

@Component({
  selector: 'rp-home-root',
  standalone: true,
  template: `
    <div class="app-wrapper">
      <router-outlet />
    </div>
  `,
  styleUrl: './app.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RouterOutlet],
})
export class AppComponent implements OnInit, AfterViewInit {
  timeoutId: NodeJS.Timeout = null;

  private readonly p5ScriptUrl = 'https://cdnjs.cloudflare.com/ajax/libs/p5.js/1.6.0/p5.min.js';

  private readonly _countriesService = inject(CountriesService);
  private readonly _languageService = inject(LanguageService);
  private readonly _platformId = inject(PLATFORM_ID);
  private readonly _router = inject(Router);
  private readonly _translateService = inject(TranslateService);
  private readonly _document = inject(DOCUMENT);
  private readonly _renderer = inject(Renderer2);

  ngOnInit(): void {
    this._setLanguage();
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this._platformId)) {
      this._removeCover();

      setTimeout(() => {
        AOS.init({
          once: true,
          offset: 100,
          duration: 900,
          delay: 100,
          easing: 'ease-in-out',
        });

        window.dispatchEvent(new Event('scroll'));
        AOS.refreshHard();
      }, 200);
    }

    this._router.events
      .pipe(
        tap(event => {
          const staticPages = Object.values(staticContentPageRouting);
          const isNavigationToStaticPage =
            event instanceof NavigationStart && staticPages.find(page => event.url.includes(page));

          if (isPlatformBrowser(this._platformId) && isNavigationToStaticPage) {
            this._initCover();
          }
        }),
        filter(event => event instanceof NavigationEnd),
      )
      .subscribe((event: NavigationEnd) => {
        if (event.url !== '/' && this._cover()) {
          this._removeCover();
        }

        if (!isPlatformBrowser(this._platformId)) {
          return;
        }

        const withCursorAnimationPage =
          Object.values(staticContentPageRouting).includes(event.url.slice(1)) || event.url === '/';

        if (withCursorAnimationPage) {
          this._loadP5Script();
        } else {
          this._unloadP5Script();
        }
      });
  }

  private _cover(): HTMLElement {
    return this._document.querySelector('.cover') as HTMLElement;
  }

  private _removeCover(): void {
    if (!this._cover()) return;

    clearTimeout(this.timeoutId);
    this._renderer.setProperty(this._cover(), 'style', 'opacity: 0');
    this.timeoutId = setTimeout(() => {
      this._cover().remove();
    }, 1000);
  }

  private _initCover(): void {
    const cover = this._document.createElement('div');
    cover.className = 'cover';
    this._renderer.appendChild(this._document.body, cover);
  }

  private _setLanguage(): void {
    const currentLanguage = this._languageService.getCurrentLanguage();

    this._translateService.use(currentLanguage);
    this._countriesService.registerLocale(currentLanguage);
  }

  private _loadP5Script(): void {
    const p5Script = this._document.getElementById('p5-js-script');

    if (p5Script) return;

    const script = this._document.createElement('script');
    script.src = this.p5ScriptUrl;
    script.id = 'p5-js-script';
    script.async = true;
    this._document.body.appendChild(script);
  }

  private _unloadP5Script(): void {
    const script = this._document.getElementById('p5-js-script');
    if (isPlatformBrowser(this._platformId)) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const p5 = (window as any)['cleanupP5'];

      if (script && p5) {
        p5();
        script.remove();
      }
    }
  }
}
