import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  HostListener,
  input,
  signal,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormsModule,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
} from '@angular/forms';
import { NgClass } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';
import { DropdownPosition, NgSelectModule } from '@ng-select/ng-select';

import { ValidationMessageComponent } from '../../../../validation-message/src';

type SelectSize = 'sm' | 'md';

@Component({
  selector: 'ui-select',
  standalone: true,
  templateUrl: './select.component.html',
  styleUrl: './select.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgSelectModule, TranslateModule, ValidationMessageComponent, FormsModule, NgClass],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SelectComponent),
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
  ],
})
export class SelectComponent<T> implements ControlValueAccessor {
  items = input<T[]>([]);
  multiple = input<boolean>(false);
  placeholder = input<string>('');
  bindLabel = input<string>('name');
  size = input<SelectSize>('md');
  dropdownPosition = input<DropdownPosition>('auto');
  isLanguageSelector = input(false, { transform: booleanAttribute });

  value = signal<T | null>(null);
  control = signal<AbstractControl | null>(null);

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    const element = event.target as HTMLElement;
    const classNames = ['ng-option', 'ng-option-label'];
    const containsClasses = classNames.some(className => element.classList.contains(className));

    if (containsClasses) {
      return;
    }

    const windowHeight = window.innerHeight;
    const distanceToTop = event.clientY;
    const distanceToBottom = windowHeight - distanceToTop;
    const offset = 50;
    const selectDropdownMaxHeight =
      distanceToTop > distanceToBottom ? distanceToTop : distanceToBottom;

    document.documentElement.style.setProperty(
      '--select-dropdown-max-height',
      `${selectDropdownMaxHeight - offset}px`,
    );
  }

  onChange = (value: T): void => undefined;
  onTouched = (): void => undefined;

  writeValue(value: T): void {
    this.value.set(value);
  }

  registerOnChange(fn: (value: T) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    this.control.set(control);

    return null;
  }
}
